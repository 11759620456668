import { IDeliveryDiscountData } from '@/store/ducks/deliveryDiscount/types';

interface Props {
  discount: IDeliveryDiscountData;
}

const ShippingFiltersText = ({ discount }: Props) => {
  const messages = [];

  if (discount.categories.length) {
    const categories = discount.categories.map((category) => category.webapp_name || category.name);

    messages.push(
      <>
        da categoria <b>{categories.join(', ')}</b>
      </>,
    );
  }

  if (discount.groups.length) {
    const groups = discount.groups.map((group) => group.webapp_name || group.name);

    messages.push(
      <>
        do grupo <b>{groups.join(', ')}</b>
      </>,
    );
  }

  if (discount.brand) {
    messages.push(
      <>
        da marca <b>{discount.brand}</b>
      </>,
    );
  }

  return (
    <>
      {messages.map((message, index) => (
        <span key={index}>
          {message}
          {index < messages.length - 1 && ', e '}
        </span>
      ))}
    </>
  );
};

export default ShippingFiltersText;
