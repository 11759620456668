import { memo } from 'react';
import { Badge, Card } from 'react-bootstrap';
import { FaTruckFast } from 'react-icons/fa6';

import ShippingFiltersText from '@/resources/components/product/freeShippingAlert/rulesModal/ShippingFiltersText';
import { formatDate } from '@/services/dates';
import { maskMoney } from '@/services/global';
import { IDeliveryDiscountData } from '@/store/ducks/deliveryDiscount/types';

interface Props {
  discount: IDeliveryDiscountData;
}

function DeliveryDiscountItem({ discount }: Props) {
  const { type_discount, start_date, end_date, min_price_order, max_distance, price_discount } =
    discount;

  const hasDiscount = Boolean(
    discount?.brand || discount?.categories?.length || discount?.groups?.length,
  );

  return (
    <Card className="discount-card">
      <Card.Body className="discount-card-body">
        <div className={`discount-icon ${type_discount}-shipping`}>
          <FaTruckFast />

          <Badge className={`discount-badge ${type_discount}-badge`}>
            {type_discount === 'free' ? 'FRETE GRÁTIS' : 'DESCONTO'}
          </Badge>
        </div>

        <div className="discount-info">
          <div className="discount-info-title">
            {hasDiscount ? (
              <>
                <b>{type_discount === 'free' ? <>FRETE GRÁTIS</> : <>DESCONTO NO FRETE</>}: </b>
                Produtos <ShippingFiltersText discount={discount} />.
              </>
            ) : (
              <b>{type_discount === 'free' ? <>FRETE GRÁTIS</> : <>DESCONTO NO FRETE</>} </b>
            )}
          </div>

          <div className="discount-info-description">
            <b>Regulamento: </b>
            {type_discount === 'free' && (
              <>
                FRETE GRÁTIS válido apenas para compras de produtos{' '}
                <ShippingFiltersText discount={discount} /> feitas entre{' '}
                <b>{formatDate(start_date)}</b> até <b>{formatDate(end_date)}</b> com valor acima de{' '}
                <b>{maskMoney(min_price_order, true)}</b> e entrega em até <b>{max_distance}km</b>.
              </>
            )}

            {type_discount === 'price' && (
              <>
                Desconto de <b>{maskMoney(price_discount, true)}</b> para compras feitas entre{' '}
                <b>{formatDate(start_date)}</b> até <b>{formatDate(end_date)}</b> com valor acima de{' '}
                <b>{maskMoney(min_price_order, true)}</b> e entrega em até <b>{max_distance}km</b>.
              </>
            )}
          </div>
        </div>
      </Card.Body>
    </Card>
  );
}

export default memo(DeliveryDiscountItem);
